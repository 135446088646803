<template>
  <div class="height position-relative overflow-hidden" >
    <div v-for="(poster, key) in posters" :key="key" class="position-absolute top-0 bottom-0 start-0 end-0" v-touch:swipe="swipeHandler">
      <transition :name="slideTransition">
        <div class="position-absolute w-100" v-if="key === activeSlide" style="height: 180px;">
          <Image :imageName="poster.imageUrl" height="180" theClass="img-fluid slider_item"  />
        </div>
      </transition>
    </div>
    <div class="prev start-0 top-50 translate-middle-y" @click="prev">
      <ios-arrow-back />
    </div>
    <div class="next end-0 top-50 translate-middle-y" @click="next"><ios-arrow-forward /></div>
    <div class="pagination position-absolute start-50 translate-middle-x">
      <div class="dot" :class="{active: key === activeSlide}" v-for="(poster, key) in posters" :key="key" @click="goToSlide(key)"></div>
    </div>
  </div>
</template>
<script>

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    'ios-arrow-forward': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-forward.vue')),
    'ios-arrow-back': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-back.vue')),

    Image: defineAsyncComponent(() => import('@/components/Image.vue'))
  },
  name: 'LastestAppealsSliderMobile',
  props: ['posters'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      activeSlide: 0,
      slideTransition: 'slideLeft'
    }
  },
  methods: {
    goto (val) {
      window.scroll(0, 0)
      this.$router.push('/project/' + val)
    },
    goToSlide (val) {
      if (val > this.activeSlide) {
        this.slideTransition = 'slideLeft'
      } else {
        this.slideTransition = 'slideRight'
      }
      this.activeSlide = val
    },
    swipeHandler (direction) {
      if (direction === 'right') {
        this.prev()
      } else if (direction === 'left') {
        this.next()
      }
    },
    next () {
      this.slideTransition = 'slideLeft'
      if (this.activeSlide + 1 === this.posters.length) {
        this.activeSlide = 0
      } else {
        this.activeSlide++
      }
    },
    prev () {
      this.slideTransition = 'slideRight'
      if (this.activeSlide === 0) {
        this.activeSlide = this.posters.length - 1
      } else {
        this.activeSlide--
      }
    }
  }
}
</script>

<style scoped>
.slider_item {
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: 17px;
}
.height {
  height: 200px;
}
.prev, .next {
  color: #fff;
  opacity: 1;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2;
  position: absolute;
}

.pagination {
  bottom: 7px;
  z-index: 2;
}
.dot {
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  opacity: .2;
  margin: 0 6px;
  cursor: pointer;
}

.dot.active {
  opacity: 1;
  background: var(--gold-color);
}
.slideLeft-enter-active, .slideLeft-leave-active {
  transition: all 0.5s ease-out;
}
.slideLeft-enter-from {
  transform: translateX(100%);
}
.slideLeft-leave-to {
  transform: translateX(-100%);
}

.slideRight-enter-active, .slideRight-leave-active {
  transition: all 0.5s ease-out;
}
.slideRight-enter-from {
  transform: translateX(-100%);
}
.slideRight-leave-to {
  transform: translateX(100%);
}
</style>
